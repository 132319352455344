import {IControllers, Loader} from "../loader";
import {IController} from "../controller";

export class CmpLoader extends Loader {
    /* STATIC */
    public static override async build(): Promise<IControllers> {
        const onload: IController[] = [];
        //if (window.location.pathname.endsWith(".html")) {
            const [
                {CmpController},
            ] = await Promise.all([
                import(/* webpackChunkName: "encuesta" */ `../controllers/cmp`),
            ]);

            onload.push(...[
                CmpController,
            ])
        //}
        return {
            ...await super.build(),
            onload,
        };
    }

    /* INSTANCE */
}
