import {IRespuesta} from "services-comun/modules/net/interface";

export class FrontendRequest {
    /* STATIC */
    private static async parseRespuesta<T>(respuesta: Response, url: string): Promise<T> {
        if (respuesta.ok) {
            const data: IRespuesta<T> = await respuesta.json();
            if (data.ok && data.data!=undefined) {
                return data.data;
            }
            return Promise.reject(data.info?.message??`Error cargando ${url}`);
        }
        return Promise.reject(respuesta.statusText);
    }

    protected static async get<T>(url: string): Promise<T> {
        try {
            const respuesta = await fetch(url);
            return await this.parseRespuesta(respuesta, url);
        } catch (e) {
            return Promise.reject(`Error cargando ${url}`);
        }

    }

    protected static async getAuth<T>(url: string, auth: string): Promise<T> {
        try {
            const respuesta = await fetch(url, {
                headers: {
                    "Authorization": auth,
                }
            });
            return await this.parseRespuesta(respuesta, url);
        } catch (e) {
            return Promise.reject(`Error cargando ${url}`);
        }

    }

    protected static async postJSON<S,T>(url: string, data: S): Promise<T> {
        try {
            const respuesta = await fetch(url, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            return await this.parseRespuesta(respuesta, url);
        } catch (e) {
            return Promise.reject(`Error cargando ${url}`);
        }

    }

    protected static async postJSONAuth<S,T>(url: string, auth: string, data: S): Promise<T> {
        try {
            const respuesta = await fetch(url, {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Authorization": auth,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            return await this.parseRespuesta(respuesta, url);
        } catch (e) {
            return Promise.reject(`Error cargando ${url}`);
        }

    }

    /* INSTANCE */
}
