import {IControllers, Loader} from "../loader";
import {IController} from "../controller";
import {EDGamesController} from "../controllers/ed-games";

export class EDGamesLoader extends Loader {
    /* STATIC */
    public static override async build(): Promise<IControllers> {
        const onload: IController[] = [];
        if (window.location.pathname.startsWith("/ed-games/")) {
            const [
                {EDGamesController},
            ] = await Promise.all([
                import(/* webpackChunkName: "encuesta" */ `../controllers/ed-games`),
            ]);

            onload.push(...[
                EDGamesController,
            ])
        }
        return {
            ...await super.build(),
            onload,
        };
    }

    /* INSTANCE */
}
