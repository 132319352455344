import {Dominio as DominioBase, ISubdominioCompleto} from "services-comun/modules/net/config/dominio";
import {ESubdominio} from "../../services/portadillas/interface";

class DominioED extends DominioBase {
    /* STATIC */
    public static readonly APUESTAS: ISubdominioCompleto = {
        nombre: "apuestas",
        scheme: "https",
    };
    public static readonly SERVICES: ISubdominioCompleto = {
        nombre: "services",
        scheme: "https",
    };
    public static readonly IMAGENES_CDN: ISubdominioCompleto = {
        nombre: "imagenes-cdn",
        scheme: "https",
    };
    public static readonly FOTOS00: ISubdominioCompleto = {
        nombre: "fotos00",
        scheme: "https",
    };
    public static readonly FOTOS01: ISubdominioCompleto = {
        nombre: "fotos01",
        scheme: "https",
    };
    public static readonly FOTOS02: ISubdominioCompleto = {
        nombre: "fotos02",
        scheme: "https",
    };
    public static readonly AMP: ISubdominioCompleto = {
        nombre: "amp",
        scheme: "https",
    };
    public static readonly REDACCION: ISubdominioCompleto = {
        nombre: "redaccion",
        scheme: "https",
    };

    public static INSTANCE: DominioED;
    static {
        this.INSTANCE = new this();
    }

    /* INSTANCE */
    protected readonly SUBDOMINIO_APUESTAS = DominioED.APUESTAS;
    protected readonly SUBDOMINIO_SERVICES = DominioED.SERVICES;
    protected readonly SUBDOMINIO_IMAGENES_CDN = DominioED.IMAGENES_CDN;
    protected readonly SUBDOMINIO_FOTOS00 = DominioED.FOTOS00;
    protected readonly SUBDOMINIO_FOTOS01 = DominioED.FOTOS01;
    protected readonly SUBDOMINIO_FOTOS02 = DominioED.FOTOS02;
    protected readonly SUBDOMINIO_AMP = DominioED.AMP;
    protected readonly SUBDOMINIO_REDACCION = DominioED.REDACCION;

    public readonly APUESTAS = this.SUBDOMINIO_APUESTAS.nombre;
    public readonly SERVICES = this.SUBDOMINIO_SERVICES.nombre;
    public readonly IMAGENES_CDN = this.SUBDOMINIO_IMAGENES_CDN.nombre;
    public readonly FOTOS00 = this.SUBDOMINIO_FOTOS00.nombre;
    public readonly FOTOS01 = this.SUBDOMINIO_FOTOS01.nombre;
    public readonly FOTOS02 = this.SUBDOMINIO_FOTOS02.nombre;
    public readonly AMP = this.SUBDOMINIO_AMP.nombre;
    public readonly REDACCION = this.SUBDOMINIO_REDACCION.nombre;

    private constructor() {
        const listado = [
            DominioED.APUESTAS,
            DominioED.SERVICES,
            DominioED.IMAGENES_CDN,
            DominioED.FOTOS00,
            DominioED.FOTOS01,
            DominioED.FOTOS02,
            DominioED.AMP,
            DominioED.REDACCION,
        ];
        super({
            dominio: "estadiodeportivo.com",
            subdominios: {
                listado: listado,
                habilitados: listado.map(actual=>actual.nombre),
                redirigidos: [],
            },
        });
    }

    public toDominio(subdominio: ESubdominio): string {
        return subdominio==ESubdominio.apuestas?this.APUESTAS:this.WWW;
    }

    public toSubdominio(host: string): ESubdominio {
        const dominio = this.searchHost(host);
        switch(dominio){
            case DominioED.APUESTAS.nombre:
                return ESubdominio.apuestas;
            default:
                return ESubdominio.www;
        }
    }

}

const dominio = DominioED.INSTANCE;
export default dominio;
