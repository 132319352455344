import '../utils/polyfills';
import type {} from "services-comun/modules/types.d.ts";

import '../data/navegador';

import {CmpLoader} from "../loaders/cmp";
import init from "../utils/init";
import {DirectosLoader} from "../loaders/directos";
import {NoticiaLoader} from "../loaders/noticia";
import {EDGamesLoader} from "../loaders/ed-games";
import {GaleriaLoader} from "../loaders/galeria";
import {LoginLoader} from "../loaders/login";
import {UsuarioLoader} from "../loaders/usuario";
import {AccionesUsuarioLoader} from "../loaders/acciones-usuario";


init.addLoader(CmpLoader);
init.addLoader(DirectosLoader);
init.addLoader(NoticiaLoader);
init.addLoader(EDGamesLoader);
init.addLoader(GaleriaLoader);
init.addLoader(LoginLoader);
init.addLoader(UsuarioLoader);
init.addLoader(AccionesUsuarioLoader)
