import {IControllers, Loader} from "../loader";
import {IController} from "../controller";

export class DirectosLoader extends Loader {
    /* STATIC */
    public static override async build(): Promise<IControllers> {
        const onload: IController[] = [];
        if (window.location.pathname.endsWith(".html") && window.tipo_noticia === "directo") {
            const [
                {DirectoController},
            ] = await Promise.all([
                import(/* webpackChunkName: "encuesta" */ `../controllers/directos`),
            ]);

            onload.push(...[
                DirectoController,
            ])
        }
        return {
            ...await super.build(),
            onload,
        };
    }

    /* INSTANCE */
}
