import {IController} from "./controller";

export interface ILoader {
    build: ()=>Promise<IControllers>;
}

export interface IControllers {
    immediates: IController[];
    onload: IController[];
}

export abstract class Loader {
    /* STATIC */
    public static async build(): Promise<IControllers> {
        return {
            immediates: [],
            onload: [],
        };
    }

    /* INSTANCE */
}
