import {IControllers, Loader} from "../loader";
import {IController} from "../controller";

export class GaleriaLoader extends Loader {
    /* STATIC */
    public static override async build(): Promise<IControllers> {
        const onload: IController[] = [];
        if (window.location.pathname.endsWith(".html")) {
            const [
                {GaleriaController},
            ] = await Promise.all([
                import(/* webpackChunkName: "encuesta" */ `../controllers/galeria`),
            ]);

            onload.push(...[
                GaleriaController,
            ])
        }
        return {
            ...await super.build(),
            onload,
        };
    }

    /* INSTANCE */
}
