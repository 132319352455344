import {logRejection} from "services-comun/modules/decorators/metodo";

import {FrontendRequest} from "../frontend-backend";
import {INavegadorRegistro} from "./interface";
import dominio from "../../portal/ed";

export class NavegadoresWebFrontendRequest extends FrontendRequest {
    /* STATIC */
    private static SERVICIO: string = dominio.get(dominio.SERVICES);

    @logRejection(true)
    public static async check(data: INavegadorRegistro): Promise<string> {
        return await this.postJSON<INavegadorRegistro, string>(`${this.SERVICIO}/web/users/browser/v1/check/`, data);
    }

    /* INSTANCE */
}
