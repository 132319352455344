import {ITag} from "../tags/interface";
import {INoticiaPortadilla} from "../noticias/interface";
import {IPortadillaWeb} from "./portadilla-web";
import {TDevice} from "services-comun/modules/frontend/plantilla";
import {TTipoModulo} from "./distribucion";
import {EBanner} from "../../portal/publicidad";

export type TEstado = "O"|"R"|"V";
export type TSubdominio = "www"|"apuestas";
export enum ESubdominio {
    www = 'www',
    apuestas = 'apuestas',
}
export type TAccion = "301"|"404"|"410";

export interface IPortadillaError {
    subdominio: TSubdominio;
    url: string;
    accion: TAccion;
    redirigido?: string;
    subdominio_redirigido?: string;
}

export interface IPortadilla {
    url: string;
    subdominio: TSubdominio;
    avatar?: string;
    tipologia: string;
    fragmento_url: string;
    menu_padre?: string;
    menu_orden?: number;
    padre_id?: string;
    padre_subdominio?: TSubdominio;
    estado: TEstado;
    redirigido?: string;
    redirigido_subdominio?: TSubdominio;
    is_categoria: boolean;
    tiene_subcategorias: boolean;
    noticias: number;
    noticias_opinion: number;
    nombre: string;
    firmaGuardado: string;
    titulo?: string;
    descripcion?: string;
    robots?: string;
    keywords?: string;
    right?: string;
}

export interface IPortadillaWebNoticia extends IPortadillaWeb {
    noticias: INoticiaPortadilla[];
}

export interface IPortadillaPanel  extends IPortadilla {
    tags?: ITag[][];
    tags_excluidos?: ITag[][];
    tags_categoria?: ITag[];
    tags_anadidos?: ITag[];
}

export interface IPortadillaMenu {
    anchor: string;
    href?: string;
    title: string;
    seleccionado: boolean;
    submenu?: IPortadillaMenu[];
    submenuDosColumnas?: IPortadillaMenu[]
}

export interface ICategoria extends IPortadilla {
    subcategorias?: ICategoria[];
}

export interface ICategoriaPanel  extends ICategoria{
    tags: ITag[][]|undefined;
    tags_categoria: ITag[]|undefined;
    tags_anadidos: ITag[]|undefined;
    subcategorias?: ICategoriaPanel[];
}

export interface IEscudo {
    url: string;
    titulo?: string;
    corto?: string;
    miniatura?: string;
    miniatura2x?: string;
}

export interface IPortadillaResumen {
    url: string,
    subdominio: string,
}

export interface IPortadillaCache {
    subdominio: ESubdominio,
    portadilla?: string,
    tag?: string,
    autor?: string,
    modulo?:string,
    noticias: number[],
    opinion: number[],
    ultimas: number[],
}
