import {IControllers, Loader} from "../loader";
import {IController} from "../controller";

export class AccionesUsuarioLoader extends Loader {
    /* STATIC */
    public static override async build(): Promise<IControllers> {
        const onload: IController[] = [];
        if (window.location.pathname.includes("/acciones-usuario/")) {
            const [
                {AccionesUsuarioController},
            ] = await Promise.all([
                import(/* webpackChunkName: "encuesta" */ `../controllers/acciones-usuario`),
            ]);

            onload.push(...[
                AccionesUsuarioController,
            ])
        }
        return {
            ...await super.build(),
            onload,
        };
    }

    /* INSTANCE */
}
